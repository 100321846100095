/* Breakpoints -> 540px 720px 960px 1140px */

/* Defaults */

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
html, body {
	height: 100%;
	margin: 0;
	font-family: 'Lato', sans-serif;
	background-color: #000000;
	color: #fff;
}

a {
	font-family: 'Lato', sans-serif;
	color: #266b94;
	font-weight: 600;
}

a:hover {
	text-decoration: none;
}

p {
	font-size: 16px;
	line-height: 1.5;
}

h1 {
	font-size: 32px;
	font-weight: 600;
}

h2 {
	font-size: 24px;
	font-weight: 600;
}

h3 {
	font-size: 20px;
	font-weight: 600;
}

h4 {
	font-size: 14px;
	font-weight: 600;
}

#root, .App, .Background {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.Background {
	background-size: cover;
	background-position: center;
	opacity: 0;
	-webkit-transition: 300ms ease-in-out;
	transition: 300ms ease-in-out;
}

.Background.show {
	opacity: 0.80;
}

.row {
	display: flex;
	flex-direction: row;
}

.column {
	display: flex;
	flex-direction: column;
}

.Weather {
	font-family: 'Roboto', sans-serif;
	position: absolute;
	top: 0;
	left: 0;
	text-align: left;
	width: 100%;
	padding: 30px 30px 100px;
	box-sizing: border-box;
	background: rgb(0, 0, 0);
	background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 6%, rgba(255, 255, 255, 0) 100%)
}

.Weather .row>div {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	text-align: left;
}

.Weather .icon {
	margin-right: 10px;
}

.Weather .current-temp {
	font-size: 80px;
	margin-right: 10px;
	font-weight: 400;
	line-height: 0.9;
}

.Weather .date {
	font-size: 18px;
	line-height: 1.4;
	font-weight: 300;
}

.Weather .city {
	font-size: 28px;
	font-weight: 400;
	margin-bottom: 20px;
}

.Weather .conditions {
	padding-top: 5px;
	font-size: 13px;
	font-weight: 300;
}

.Weather .conditions>div {
	margin-bottom: 7px;
}

@media only screen and (max-width: 540px) {
	.Weather .row>div {
		justify-content: center;
	}
	.Weather .current-temp {
		font-size: 50px;
	}
}

.main {
	flex: 1 0 auto;
}

.Forecast {
	position: absolute;
	bottom: 0; left: 0;
	padding: 20px 20px 50px 20px;
	display: flex;
	flex-direction: row;
}

.Forecast .day {
	padding: 10px 5px;
	margin-right: 20px;
	background-color: rgba(0, 0, 0, 0.41);
	border-radius: 5px;
	-webkit-transition: 200ms ease-in-out;
	transition: 200ms ease-in-out;
	opacity: 0;
}

.Forecast .day.show {
	opacity: 1;
}

.Forecast .day p {
	font-size: 18px;
	margin: 0;
}

.Forecast .day .temp-max {
	font-size: 20px;
}

.Forecast .day .temp-min {
	font-size: 20px;
	color: rgba(255, 255, 255, 0.5);
}

p.photo-credit {
	font-size: 13px;
	position: absolute;
	bottom: 0px;
	right: 0;
	margin: 10px;
	opacity: 0.5;
}

p.photo-credit a {
	color: #fff;
}